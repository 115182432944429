<template>
  <div>
    <el-dialog title="新增" append-to-body :close-on-click-modal="false" width="600px" :visible.sync="dialogVisible">
      <el-form ref="formValidate" label-width="60px" :model="paramsItem" :rules="ruleValidate">
        <el-form-item label="名称" prop="paramsItemName">
          <el-input v-model="paramsItem.paramsItemName" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="值" prop="paramsItemValue">
          <el-input v-model="paramsItem.paramsItemValue" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
        <el-button type="primary" @click="handleSubmit">{{
          $t("common.save") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      paramsItem: {
        paramsItemValue: "",
        paramsItemName: ""
      },
      ruleValidate: {
        paramsItemName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        paramsItemValue: [
          { required: true, message: "值不能为空", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.formValidate.resetFields();
      });
    },

    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.$emit("save-success", this.paramsItem);
          this.dialogVisible = false;
        }
      });
    }
  }
};
</script>