<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" @close="closeHandle" top="2vh">
    <el-form v-loading="contentLoading" ref="formValidate" label-width="80px" :model="paramMange" :rules="ruleValidate">
      <div class="vm-separate">
        <el-form-item label="参数号" prop="no">
          <el-input v-model="paramMange.no" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="参数名" prop="name">
          <el-input v-model="paramMange.name" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item label="小数位" prop="decimalPlace">
          <el-input v-model="paramMange.decimalPlace" :placeholder="$t('common.pleaseEnter')"
            auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="默认值" prop="defaultValue">
          <el-input v-model="paramMange.defaultValue" :placeholder="$t('common.pleaseEnter')"
            auto-complete="off"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item label="范围" prop="range">
          <el-input v-model="paramMange.range" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input v-model="paramMange.unit" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item label="别名" prop="alias">
          <el-input v-model="paramMange.alias" :placeholder="$t('common.pleaseEnter')" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="组件类型" prop="componentType">
          <el-select v-model="paramMange.componentType" placeholder="请选择" style="width: 100%;" @change="changeHandle">
            <el-option label="输入框" :value="1"></el-option>
            <el-option label="选择框" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div style="padding-left: 80px;margin-bottom: 14px;" v-if="paramMange.componentType === 2">
        <el-button type="primary" style="margin-bottom: 14px;" @click="$refs.editPage.open()">新增</el-button>
        <el-table :data="paramMange.paramDetails" style="width: 100%;">
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="值" prop="value" width="100"></el-table-column>
          <el-table-column :label="$t('common.operation')" width="100" align="center">
            <template slot-scope="scope">
              <el-button style="color: red;" type="text"
                @click="paramMange.paramDetails.splice(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="vm-separate">
        <el-form-item label="所属模块" prop="paramTypeIds">
          <el-select v-model="paramMange.paramTypeIds" multiple placeholder="请选择" style="width: 100%;">
            <el-option v-for="item in paramsModule" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="注释" prop="note">
        <quill-editor v-model="paramMange.note" :options="editorOptions"></quill-editor>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">{{
        $t("common.save") }}</el-button>
    </span>

    <params-items ref="editPage" @save-success="addHandle"></params-items>
  </el-dialog>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css"; // 引入样式
import ParamsItems from "./ParamsItems";

export default {
  components: {
    quillEditor, ParamsItems
  },

  data() {
    return {
      contentLoading: false,
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗、斜体、下划线、删除线
            ["blockquote", "code-block"], // 引用、代码块
            [{ header: 1 }, { header: 2 }], // 标题
            [{ list: "ordered" }, { list: "bullet" }], // 列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文字方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题等级
            [{ color: [] }, { background: [] }], // 字体颜色、背景色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除格式
            ["link", "image", "video"] // 链接、图片、视频
          ]
        }
      },
      paramsModule: [],
      dialogVisible: false,
      submitLoading: false,
      paramMange: {
        id: 0,
        name: "",
        no: "",
        decimalPlace: 0,
        defaultValue: "",
        range: "",
        note: "",
        unit: "",
        alias: "",
        paramTypeIds: [],
        componentType: 1,
        paramDetails: [],
        
      },
      ruleValidate: {
        name: [
          { required: true, message: "参数名不能为空", trigger: "blur" },
        ],
        no: [
          { required: true, message: "参数号不能为空", trigger: "blur" },
        ],
        decimalPlace: [
          { required: true, message: "小数位不能为空", trigger: "blur" },
        ],
        componentType: [
          { required: true, message: "组件类型不能为空", trigger: "blur" },
        ]
      },
    };
  },
  computed: {
    title() {
      return this.paramMange.id === 0 ? this.$t("common.add") : this.$t("common.edit");
    },
  },

  mounted() {
    this.getParamsModule();
  },

  methods: {

    closeHandle() {
      this.$refs.formValidate.resetFields();
      this.paramMange.typeId = "";
      this.paramMange.typeNames = "";
      this.paramMange.typeNos = "";
    },

    getParamsModule() {
      this.$http.get("paramTypes/getAllList").then(res => {
        this.paramsModule = res.data;
      });
    },

    changeHandle() {
      this.paramMange.paramDetails = [];
    },

    addHandle(data) {
      this.paramMange.paramDetails.push({
        name: data.paramsItemName,
        value: data.paramsItemValue
      });
    },

    open(id) {
      this.dialogVisible = true;
      this.paramMange.id = id;
      if (id) {
        this.getData();
      }
    },

    getData() {
      this.contentLoading = true;
      this.$http.get("debugParamManages/" + this.paramMange.id).then(res => {
        this.paramMange = res.data;
        this.contentLoading = false;
      }).catch((error) => {
        this.contentLoading = false;
        this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
      });
    },

    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$http.save("debugParamManages", this.paramMange).then(res => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.quill-editor .ql-container {
  height: 200px;
}
</style>